// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import apolloClient from '../apolloClient';
import { gql } from '@apollo/client';
import { addToast } from 'helpers/toasts';
import pageData from 'helpers/pageData';
import homeQueries from 'components/pages/HomePage/queries';
import TitleAddon from 'components/pages/HomePage/TitleAddon';
import { emptyFn, redirect as jswRedirect } from 'jsw';

import HOME_PAGE from 'queries/AdminHomePage.graphql';

const redirect = url => {
    jswRedirect(url);
    return new Promise(emptyFn);
};
const errors = response => (response.reason?.graphQLErrors ?? []).map(({ message, extensions }) => extensions?.debugMessage || message);

export const homeRoute = {
    path: 'home',
    componentLoader: () => import(/* webpackChunkName: "components/pages/HomePage" */'components/pages/HomePage'),
    loader: async () => {
        const { data: { viewer: { type } } } = await apolloClient.query({
            query: gql`
                                                query {
                                                    viewer {
                                                        login
                                                        type
                                                    }
                                                }
                                            `,
        });

        if (type !== 'ADMIN') {
            return redirect('/admin/home/');
        }

        const { pageSidebar } = pageData();
        if (pageSidebar?.isPowerUserPanel) {
            return redirect('/smb/');
        }

        const response = await Promise.allSettled([
            ...Object.values(homeQueries).map(query => apolloClient.query({ query })),
            apolloClient.query({ query: HOME_PAGE }),
        ]);

        const data = Object.keys(homeQueries).reduce((acc, block, i) => ({
            ...acc,
            [block]: {
                data: response[i].value?.data,
                errors: errors(response[i]),
            },
        }), {});

        const layoutResponse = response.at(-1);
        errors(layoutResponse).forEach(message => {
            addToast({ intent: 'danger', message });
        });
        return { data };
    },
    handle: {
        helpContext: 'home.admin',
        titleRightAddon: <TitleAddon />,
        pageContentHeader: { className: 'home-page-heading' },
    },
    children: [{
        path: 'customize',
        componentLoader: () => import(/* webpackChunkName: "components/pages/HomeCustomizationPage" */'components/pages/HomePage/Customization'),
        loader: () => apolloClient.query({ query: require('components/pages/HomePage/queries/Customization.graphql') }),
    }],
};
