// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

/* eslint-disable react/require-render-return */

import { Component } from './component';
import { DynamicPopupHint } from './dynamic-popup-hint';
import Locale from './Locale';
import render from './render';
import ce from './createElement';
import estimatePassword from '../helpers/estimatePassword';

import './password-meter.less';

export class PasswordMeter extends Component {
    _initConfiguration({ observe, ...config }) {
        this._passwordElement = document.getElementById(observe);
        this._passwordElement.addEventListener('keyup', this._onChange.bind(this));
        // Temporary workaround for custom prototype's event 'plesk:passwordGenerated'
        this._passwordElement.addEventListener('dataavailable', this._onChange.bind(this));

        super._initConfiguration({
            id: `${this._passwordElement.id}-meter`,
            tag: 'span',
            cls: 'password-strength',
            locale: Locale.getSection('components.password.meter'),
            ...config,
        });
    }

    _initComponentElement() {
        super._initComponentElement();

        render(this._componentElement, [
            ce('i', {
                onrender: el => {
                    this._progress = el;
                },
            }),
            ce('b', {
                onrender: el => {
                    this._verdict = el;
                },
            }),
        ]);
    }

    setProgress(strength) {
        const level = ['VeryWeak', 'Weak', 'Medium', 'Strong', 'VeryStrong'].indexOf(strength);
        this._progress.setAttribute('style', `width: ${(level + 1) * 20}%`);
    }

    async _onChange() {
        const { password, strength, suggestions } = await estimatePassword(this._passwordElement.value);
        if (password !== this._passwordElement.value) {
            return;
        }

        this._updateVisibility();
        this.setProgress(strength);
        this._updateHint(suggestions);
        this._updateVerdict(strength);
        this._updateColor(strength);
    }

    _updateVisibility() {
        if (this._passwordElement.value === '') {
            this._componentElement.style.display = 'none';
            this._hintContainer.style.display = 'none';
        } else {
            this._componentElement.style.display = '';
            this._hintContainer.style.display = '';
        }
    }

    _updateColor(strength) {
        const newClass = `password-strength${strength.replace(/[A-Z]/g, c => `-${c.toLowerCase()}`)}`;
        if (this._oldClass === newClass) {
            return;
        }
        if (this._oldClass) {
            this._componentElement.classList.remove(this._oldClass);
        }
        this._componentElement.classList.add(newClass);
        this._oldClass = newClass;
    }

    _updateHint(suggestions) {
        this._tooltip.setContent(suggestions.length ? [
            this.lmsg('description'),
            ce('br'),
            ce('ul', suggestions.map(suggestion => ce('li.asd', suggestion))),
        ] : this.lmsg('yourPasswordIsStrong'));
    }

    _updateVerdict(strength) {
        this._verdict.innerHTML = this.lmsg(`verdict${strength}`);
    }

    render() {
        super.render();

        render(this._componentElement, ce('span.hint-inline.hint-info', {
            onrender: el => {
                this._hintContainer = el;
            },
        }, ce('span', {
            onrender: el => {
                this._tooltip = new DynamicPopupHint.Instance({
                    title: this.lmsg('title'),
                    waitMsg: '',
                    url: '',
                    placement: 'right',
                    target: el,
                });
            },
        }, '(?)')), 'after');
        this._updateVisibility();

        if (this._passwordElement.value) {
            this._onChange();
        }
    }
}
