// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useLayoutEffect } from 'react';
import { getComponent } from 'jsw';

const LogsPage = () => {
    useLayoutEffect(() => () => {
        getComponent('log-browser')?.remove();
    }, []);

    return null;
};

export default LogsPage;
