// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Container } from './container';
import render from './render';

export class ListContainer extends Container {
    _initConfiguration(config) {
        super._initConfiguration({
            tag: 'ul',
            ...config,
        });
    }

    _renderItem(item) {
        let renderTargetId = null;
        const isEmptyItem = Array.isArray(item) && !item.length;

        if (!isEmptyItem) {
            renderTargetId = `${this._id}-item-${item.getId()}`;
        }

        let cls = this._getConfigParam('itemClass', '');

        if (this._getConfigParam('renderLastItemClass', '') && this._items[this._items.length - 1] === item) {
            cls = 'last';
        }

        if (isEmptyItem) {
            cls += ` ${this._getConfigParam('emptyItemClass', '')}`;
        }

        if (cls) {
            cls = ` class="${cls}"`;
        }

        render(document.getElementById(this._contentAreaId), `<li ${renderTargetId ? `id="${renderTargetId}"` : ''}${cls}></li>`);

        if (!isEmptyItem) {
            render(document.getElementById(renderTargetId), item);
        }
    }
}
