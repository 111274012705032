// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Tooltip, Locale, Cookie } from 'jsw';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Dropdown, Menu, MenuItem, Button, Icon, isRtl, Text } from '@plesk/ui-library';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'common/hooks';
import SubscriptionSwitcher from './SubscriptionSwitcher';
import NotificationCenter from './NotificationCenter';
import HelpMenu from './HelpMenu';

import ACCOUNT_MENU_QUERY from 'queries/AccountMenu.graphql';
import MAIN_HEADER_NAVBAR_QUERY from 'queries/MainHeaderNavbar.graphql';

const Translate = Locale.getTranslate('components.shortcuts');
// eslint-disable-next-line max-len
const IconSun = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path fill="currentColor" fillRule="evenodd" d="M7 .5a.5.5 0 0 1 1 0v1a.5.5 0 0 1-1 0v-1Zm0 13a.5.5 0 0 1 1 0v1a.5.5 0 0 1-1 0v-1Zm-7-6c0 .28.22.5.5.5h1a.5.5 0 0 0 0-1h-1a.5.5 0 0 0-.5.5Zm13.5.5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1ZM2.2 2.2a.5.5 0 0 0 0 .7l.7.71a.5.5 0 0 0 .71-.7l-.7-.71a.5.5 0 0 0-.71 0Zm9.19 9.9a.5.5 0 1 1 .7-.71l.71.7a.5.5 0 0 1-.7.71l-.71-.7Zm-9.2.7c.2.2.52.2.71 0l.71-.7a.5.5 0 1 0-.7-.71l-.71.7a.5.5 0 0 0 0 .71Zm9.9-9.19a.5.5 0 0 1-.7-.7l.7-.71a.5.5 0 1 1 .71.7l-.7.71ZM12 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-1 0a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" /></svg>;
// eslint-disable-next-line max-len
const IconMoon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><mask id="a" fill="#fff"><path fillRule="evenodd" d="M5 13A7 7 0 0 0 8.78.1a7.5 7.5 0 1 1-7.2 12.01C2.6 12.68 3.77 13 5 13Z" clipRule="evenodd" /></mask><path fill="currentColor" d="m8.78.1.17-.98-.7 1.83.53-.84Zm-7.2 12.01.5-.87L.8 12.73l.79-.62ZM11 6a6 6 0 0 1-6 6v2a8 8 0 0 0 8-8h-2ZM8.24.95A6 6 0 0 1 11 6h2A8 8 0 0 0 9.32-.73L8.24.95Zm.37.14A6.5 6.5 0 0 1 14 7.5h2A8.5 8.5 0 0 0 8.95-.88L8.61 1.1ZM14 7.5A6.5 6.5 0 0 1 7.5 14v2c4.7 0 8.5-3.8 8.5-8.5h-2ZM7.5 14a6.49 6.49 0 0 1-5.13-2.5L.8 12.73A8.49 8.49 0 0 0 7.5 16v-2ZM5 12a5.97 5.97 0 0 1-2.93-.76l-.97 1.74A7.97 7.97 0 0 0 5 14v-2Z" mask="url(#a)" /></svg>;

const AccountMenu = ({ items }) => {
    const isHideText = useMediaQuery('(max-width: 767px)');
    const { data: { viewer: user } = {} } = useQuery(ACCOUNT_MENU_QUERY);

    if (!user) {
        return null;
    }

    return (
        <Dropdown
            menu={(
                <Menu>
                    {items.map(({ title, href, icon, dataType }) => (
                        <MenuItem
                            key={href}
                            component="a"
                            icon={icon}
                            href={href}
                            data-type={dataType}
                        >
                            {title}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            menuPlacement="bottom-end"
        >
            <Button id="account-menu" icon="user" tooltip={<Translate content="loggedInAs" />} ghost caret={!isHideText}>
                {isHideText ? null : <Text truncate>{user.pname || user.login}</Text>}
            </Button>
        </Dropdown>
    );
};

AccountMenu.propTypes = {
    items: PropTypes.array.isRequired,
};

const renderTooltips = () => {
    document.querySelectorAll('.main-header-navbar-nav__item').forEach(item => {
        if (item.dataset.description) {
            Tooltip.init(item, {
                text: item.dataset.description,
            });
        }
    });
};

const MainHeaderNavbar = ({
    backToAdminLink = undefined,
    returnUrl = undefined,
    accountMenu = undefined,
    subscriptionSwitcher = undefined,
    headerCustomButtons,
    helpMenu = undefined,
}) => {
    const isHideBackToAdminText = useMediaQuery('(max-width: 1310px)');
    const isHideLogo = useMediaQuery('(max-width: 1140px)');
    const { data: { viewer: user, config } = {} } = useQuery(MAIN_HEADER_NAVBAR_QUERY);
    const [theme, setTheme] = useState(() => Cookie.get('theme') ?? config?.branding?.theme);

    const handleToggleTheme = () => {
        const newTheme = theme === 'dark' ? 'default' : 'dark';
        document.documentElement.setAttribute('data-theme', newTheme);
        Cookie.setPermanent('theme', newTheme, '/');
        setTheme(newTheme);
    };

    useEffect(() => {
        renderTooltips();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="main-header-navbar">
            <ul className="main-header-navbar-nav">
                {backToAdminLink ? (
                    <li className="top-back">
                        <Button
                            component="a"
                            icon={<Icon name="chevron-left" flipHorizontal={isRtl()} />}
                            href={backToAdminLink}
                            ghost
                        >
                            {isHideBackToAdminText ? null : <Translate content="backToAdmin" />}
                        </Button>
                    </li>
                ) : null}
                {accountMenu ? (
                    <li className="top-user">
                        <AccountMenu {...accountMenu} />
                    </li>
                ) : null}
                {accountMenu && subscriptionSwitcher ? (
                    <li className="top-webspace">
                        <SubscriptionSwitcher
                            returnUrl={returnUrl}
                            {...subscriptionSwitcher}
                        />
                    </li>
                ) : null}
                {user?.type === 'ADMIN' && config?.notification?.panel?.enabled ? (
                    <li className="top-notification-center">
                        <NotificationCenter />
                    </li>
                ) : null}
                {headerCustomButtons.map(({ id, link, target, icon, title, description }) => (
                    <li
                        key={id}
                        id={`extNavHeader-${id}`}
                        className={classNames('main-header-navbar-nav__item', `main-header-navbar-nav__item--${id}`)}
                        data-description={description}
                    >
                        <Button
                            component="a"
                            href={link}
                            target={target}
                            rel={target ? 'noopener noreferrer' : null}
                            icon={<Icon src={id === 'advisor-1' ? require('icons/16/plesk/top-advisor.svg') : icon} alt={title} />}
                            ghost
                        />
                    </li>
                ))}
                {helpMenu ? (
                    <li className="top-help">
                        <HelpMenu {...helpMenu} />
                    </li>
                ) : null}
                <li className="top-theme-switcher">
                    <Button
                        className="theme-switcher"
                        ghost
                        icon={theme === 'dark' ? <Icon>{IconSun}</Icon> : <Icon>{IconMoon}</Icon>}
                        onClick={handleToggleTheme}
                        tooltip={theme === 'dark' ? <Translate content="darkThemeOff" /> : <Translate content="darkThemeOn" />}
                        data-type={theme === 'dark' ? 'dark-mode-on' : 'dark-mode-off'}
                    />
                </li>
                {user.logo.isCustom && !isHideLogo ? (
                    <li className="top-logo">
                        <a href="http://www.plesk.com" target="_blank" rel="noopener noreferrer">
                            <img src={require('images/logos/powered-logo.png')} alt="" />
                        </a>
                    </li>
                ) : null}
            </ul>
        </div>
    );
};

MainHeaderNavbar.propTypes = {
    backToAdminLink: PropTypes.string,
    returnUrl: PropTypes.string,
    accountMenu: PropTypes.shape(AccountMenu.propTypes),
    subscriptionSwitcher: PropTypes.shape(SubscriptionSwitcher.propTypes),
    headerCustomButtons: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        link: PropTypes.string,
        target: PropTypes.string,
        icon: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    })).isRequired,
    helpMenu: PropTypes.shape(HelpMenu.propTypes),
};

export default MainHeaderNavbar;
