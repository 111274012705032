// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useLayoutEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Layout } from '@plesk/ui-library';
import Brand from './Brand';
import CookiePolicy from './CookiePolicy';

const LoginLayout = ({
    children = undefined,
    actions = undefined,
    customBackground = {
        file: undefined,
        color: '#4B4B4B',
        fit: 'fill',
    },
    ...props
}) => {
    useLayoutEffect(() => {
        document.body.classList.add('sid-login');
        return () => {
            document.body.classList.remove('sid-login');
        };
    }, []);

    return (
        <div className="login-page">
            <CookiePolicy layoutType="simplified" />
            <Layout
                type="simplified"
                className={classNames(
                    'login-page__inner',
                    `login-page__background login-page__background--${customBackground.fit}`,
                )}
                style={{
                    '--login-bg-color': customBackground.color,
                    '--login-bg-image': customBackground.file ? `url(/images/${customBackground.file})` : null,
                }}
                header={(
                    <div className="login-page-header">
                        <div className="login-page-header__brand">
                            <Brand />
                        </div>
                        {actions ? (
                            <div className="login-page-header__actions">
                                {actions}
                            </div>
                        ) : null}
                    </div>
                )}
                {...props}
            >
                <div id="main" className="login-page__content">
                    {children}
                </div>
            </Layout>
        </div>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.any,
    actions: PropTypes.any,
    customBackground: PropTypes.shape({
        file: PropTypes.string,
        color: PropTypes.string.isRequired,
        fit: PropTypes.string.isRequired,
    }),
};

export const withLoginLayout = (Component, extraProps) => {
    const Wrapper = wrapperProps => {
        const {
            contentAddon,
            actions,
            background,
            ...props
        } = {
            ...wrapperProps,
            ...(typeof extraProps === 'function' ? extraProps(wrapperProps) : extraProps),
        };

        return (
            <LoginLayout
                contentAddon={contentAddon}
                actions={actions}
                customBackground={background}
            >
                <Component {...props} />
            </LoginLayout>
        );
    };
    Wrapper.displayName = `withLoginLayout(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default LoginLayout;
