// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

/**
 * Remove tags from string
 * @param {string} str
 * @returns {string}
 */
const stripTags = str => str.replace(/<\/?[^>]+(>|$)/g, '');

export default stripTags;

