// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useNavigate, useMatch } from 'react-router-dom';
import { ContentLoader } from '@plesk/ui-library';
import { api, redirect, Tooltip } from 'jsw';
import DrawerWithProgress from 'components/common/DrawerWithProgress';

import CREATE_FORM_IN_DRAWER_QUERY from 'queries/CreateFormInDrawer.graphql';
import UPDATE_USER_SETTINGS from 'queries/UpdateUserSettings.graphql';

const useCreateFormInDrawer = (Form, dataType, pageUrl, drawerUrl) => {
    const apolloClient = useApolloClient();
    const isOpen = !!useMatch(drawerUrl);
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [task, setTask] = useState(null);
    const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS);

    useEffect(() => {
        if (isOpen) {
            setData(null);
            setTask(null);
            Tooltip.hide();
            Promise.all([
                api.get(drawerUrl),
                apolloClient.query({
                    query: CREATE_FORM_IN_DRAWER_QUERY,
                }),
            ])
                .then(([data, { data: { config, mode } }]) => {
                    if (data.redirect) {
                        redirect(data.redirect);
                        return;
                    }
                    setData({
                        ...data,
                        techDomain: {
                            url: `${config.techDomain.url}/api/subdomain`,
                            vendor: config.techDomain.vendor,
                            maxDomainLength: config.techDomain.maxDomainLength,
                        },
                        settingsEnabled: config.domainManagement.settings.enabled,
                        applicationWizardConfig: config.domainManagement.applicationWizard,
                        isMailServiceSupported: mode.isMailServiceSupported,
                    });
                });
        }
    }, [isOpen, drawerUrl, apolloClient]);

    const handleClose = () => {
        navigate(pageUrl);
    };

    const handleToggle = collapsed => {
        if (data) {
            data.settingsCollapsed = collapsed;
        }

        updateUserSettings({
            variables: {
                collapsed,
            },
        });
    };

    const drawer = (
        <DrawerWithProgress
            dataType={dataType}
            title={data ? data.pageTitle : ''}
            size="sm"
            isOpen={isOpen}
            onClose={handleClose}
            form={data ? (
                <Form
                    onCancel={handleClose}
                    onProcess={setTask}
                    onToggleDomainSettings={handleToggle}
                    {...data}
                />
            ) : null}
            task={task}
            pageUrl={pageUrl}
        >
            {data ? null : <ContentLoader />}
        </DrawerWithProgress>
    );

    return [drawer];
};

export default useCreateFormInDrawer;
