// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import escapeAttribute from '../escapeAttribute';

export const COLUMN_SELECTION = {
    cls: 'select',
    headerCls: 'select',
    hideDisabled: false,
    headerRenderer() {
        return '<input type="checkbox" class="checkbox" name="listGlobalCheckbox"/>';
    },
    renderer(item, isDisabled) {
        if (!isDisabled) {
            return `<input type="checkbox" class="checkbox" name="listCheckbox[]" value="${escapeAttribute(item.id)}"/>`;
        }

        return (
            '<div>' +
                '<span class="checkbox-disabled-wrapper"></span>' +
                '<input style="z-index: 1;" disabled="disabled" type="checkbox" class="checkbox"/>' +
            '</div>'
        );
    },
};

export const COLUMN_ACTIONS = {
    header: '',
    cls: 'min',
    sortable: false,
    renderer(item) {
        if (!item.actions || !item.actions.length) {
            return '';
        }
        const rowId = item.id ? escapeAttribute(item.id) : '';
        return (
            '<span class="btn-group list-menu">' +
                `<button type="button" class="btn btn-list-menu dropdown-toggle" data-row-id="${rowId}">` +
                    '<i class="icon">' +
                        `<img src="${require('icons/16/plesk/menu.png')}" alt="">` +
                    '</i> ' +
                    '<em class="caret"></em>' +
                '</button>' +
            '</span>'
        );
    },
};

export const ITEMS_UNLIMITED = 100000;
