// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Button, Alert } from '@plesk/ui-library';
import { Locale } from 'jsw';
import Link from 'components/common/Link';
import PropTypes from 'prop-types';
import './LicenseLimitsMessage.less';

const Translate = Locale.getTranslate('components.license-status');

const LicenseLimitsMessage = ({ buyUrl, canManageLicense }) => (
    <Alert intent="warning" className="license-limits-message">
        <Translate content={canManageLicense ? 'licenseLimitsMessage' : 'licenseLimitsMessageNoPermission'} />
        {canManageLicense && (
            <>
                <Button
                    intent="primary"
                    component="a"
                    icon="shopping-cart"
                    href={buyUrl}
                    target="_blank"
                >
                    <Translate content="buyLicenseButton" />
                </Button>
                <Link
                    to="/cp/license/primary/upload/"
                >
                    <Translate content="installLicenseButton" />
                </Link>
            </>
        )}
    </Alert>
);

LicenseLimitsMessage.propTypes = {
    buyUrl: PropTypes.string.isRequired,
    canManageLicense: PropTypes.bool.isRequired,
};

export default LicenseLimitsMessage;
