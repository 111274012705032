// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

/**
 * Converts HTML special characters to their entity equivalents.
 *
 * @param {String} value
 * @returns {String}
 */
export default value => String(value)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
