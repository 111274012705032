// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Badge, Button, Dropdown, Menu, MenuDivider, MenuItem } from '@plesk/ui-library';
import PropTypes from 'prop-types';
import { Locale } from 'jsw';
import { NotificationFilterPropType } from '../lib/PropTypes';

const Translate = Locale.getTranslate('components.notification-center');

const FilterMenu = ({ filter, filterCount, onUpdateFilter }) => {
    const menu = (
        <Menu>
            <MenuItem
                icon={filter && filter.isUnRead ? 'check-mark' : null}
                onClick={() => onUpdateFilter({ isUnRead: !filter.isUnRead, isCritical: false, isFavorite: false })}
            >
                <Translate content="filterUnread" />
            </MenuItem>
            <MenuItem
                icon={filter && filter.isCritical ? 'check-mark' : null}
                onClick={() => onUpdateFilter({ isUnRead: false, isCritical: !filter.isCritical, isFavorite: false })}
            >
                <Translate content="filterCritical" />
            </MenuItem>
            <MenuItem
                icon={filter && filter.isFavorite ? 'check-mark' : null}
                onClick={() => onUpdateFilter({ isUnRead: false, isCritical: false, isFavorite: !filter.isFavorite })}
            >
                <Translate content="filterFavorite" />
            </MenuItem>
            <MenuDivider />
            <MenuItem
                icon="remove"
                onClick={() => onUpdateFilter({
                    search: '',
                    isUnRead: false,
                    isCritical: false,
                    isFavorite: false,
                })}
            >
                <Translate content="filterReset" />
            </MenuItem>
        </Menu>
    );

    return (
        <Badge
            intent="info"
            hidden={filterCount === 0}
        >
            <Dropdown menu={menu}>
                <Button icon="filter">
                    <Translate content="filterButton" />
                </Button>
            </Dropdown>
        </Badge>
    );
};

FilterMenu.propTypes = {
    filter: NotificationFilterPropType.isRequired,
    filterCount: PropTypes.number.isRequired,
    onUpdateFilter: PropTypes.func.isRequired,
};

export default FilterMenu;
