// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@apollo/client';
import { Locale } from 'jsw';
import Link from 'components/common/Link';

import PAGE_FOOTER_QUERY from 'queries/PageFooter.graphql';

const Translate = Locale.getTranslate('components.shortcuts');

const PageFooter = () => {
    const { data: { viewer: user, config } = {} } = useQuery(PAGE_FOOTER_QUERY);

    const handleProvideRatingClick = () => {
        Plesk.require('app/rating', rating => {
            rating({
                supportUrl: config.product.supportUrl,
            });
        });
    };

    return (
        <div className="page-footer" data-type="page-footer">
            {config?.promos.pleskFooter ? (
                <a href={config.product.siteUrl} target="_blank" rel="noopener noreferrer">{'plesk.com'}</a>
            ) : null}
            {user?.type === 'ADMIN' && config?.facebook.showLikeLink ? (
                <div className="social-actions">
                    <a className="fb-link" href={config.facebook.pleskPage} target="_blank" rel="noopener noreferrer">
                        <svg className="icon" id="facebook" viewBox="0 0 32 32">
                            {/* eslint-disable-next-line max-len */}
                            <path d="M17.906,9.9V7.012a1.662,1.662,0,01,1.776-1.546h3.094V-0.052l-4.26-.022c-4.736,0-5.579,4.113-5.579,6.746L13,9.9H9.97v6.123h2.96v16.1H17.9v-16.1h4.447l0.2-2.53,0.3-3.593H17.906" />
                        </svg>
                        <Translate content="facebookJoinCommunity" />
                    </a>
                </div>
            ) : null}
            {user?.type === 'ADMIN' && config?.twitter.showFollowLink ? (
                <div className="social-actions">
                    <a className="twitter-follow-button" href={config.twitter.pleskPage} target="_blank" rel="noopener noreferrer">
                        <svg className="icon" viewBox="0 0 24 24">
                            {/* eslint-disable-next-line max-len */}
                            <path fill="#fff" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                        </svg>
                        <Translate content="twitterFollow" />
                    </a>
                </div>
            ) : null}
            {user?.type === 'ADMIN' && config?.rating.enabled ? (
                <div className="feedback-actions">
                    <a id="provide-rating-button" className="s-btn" onClick={handleProvideRatingClick}>
                        <i className="icon-opinions" /> <Translate content="provideRating" />
                    </a>
                </div>
            ) : null}
            {config?.feedback.userVoice ? (
                <div className="feedback-actions">
                    <a className="s-btn sb-feedback" href={config.feedback.userVoiceUrl} target="_blank" rel="noopener noreferrer">
                        <span><Translate content="userFeedback" /></span>
                    </a>
                </div>
            ) : null}
            <div className="feedback-actions">
                <Link to="?modals[cookie-policy-preferences]=true">
                    <Translate content="linkToCookies" />
                </Link>
            </div>
        </div>
    );
};

export default PageFooter;
