// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import Locale from '../Locale';
import createElement from '../createElement';
import escapeHtml from '../escapeHtml';

const Filters = {
    create(config, handler) {
        if (config.render) {
            return config.render(config, handler);
        }

        if (config.type === 'date') {
            return this.createDate(config, handler);
        }

        if (config.options) {
            if (config.multi) {
                return this.createMultiEnum(config, handler);
            }
            return this.createEnum(config, handler);
        }

        return this.createText(config, handler);
    },

    createText(config, handler) {
        const inputDataName = ({ name }) => name ? (name.charAt(0).toUpperCase() + name.split('').slice(1).join('')) : '';

        return createElement('input[type="text"]', {
            id: config.idPrefix ? `${config.idPrefix}-search-text-${config.name}` : null,
            name: `searchFilter[${config.name}][searchText]`,
            placeholder: config.placeholder || null,
            pattern: config.pattern || null,
            title: (config.pattern && config.placeholder) || null,
            onkeyup: (config.live && handler) || null,
            value: config.value || null,
            class: config.cls || null,
            'data-input-name': `filter${inputDataName(config)}Input`,
        });
    },

    createEnum(config, handler) {
        return createElement('select',
            {
                id: config.idPrefix ? `${config.idPrefix}-type-filter` : null,
                name: `searchFilter[${config.name}][searchText]`,
                onchange: handler || null,
            },
            this.enumOptionsView(config.options, config.value),
        );
    },

    createMultiEnum(config, handler) {
        return createElement('.dropdown',
            createElement('.input-group',
                createElement('input.form-control[type="text"][readonly]', {
                    placeholder: config.placeholder || null,
                }),
                createElement('span.input-group-btn',
                    createElement('button.btn.dropdown-toggle[type="button"]',
                        createElement('span.caret'),
                    ),
                ),
            ),
            createElement('ul.dropdown-menu.pull-right',
                {
                    onclick(event) {
                        event.stopPropagation();
                    },
                },
                Array.isArray(config.options) && config.options.length ? createElement('li',
                    createElement('div.dropdown-menu-content.checkbox-group',
                        config.options.map(function (option) {
                            return createElement('label',
                                createElement('input[type="checkbox"]', {
                                    value: option.value,
                                    checked: !config.value || config.value.indexOf(option.value) !== -1 ? true : null,
                                    onclick(event) {
                                        const element = event.target;
                                        const output = element.closest('th').querySelector('input[type=text]');
                                        const input = element.closest('th').querySelectorAll('input[type=checkbox]:checked');
                                        const total = element.closest('th').querySelectorAll('input[type=checkbox]');
                                        if (!input.length) {
                                            output.value = config.locale.lmsg('none');
                                        } else if (input.length === total.length) {
                                            output.value = '';
                                        } else {
                                            const value = [];
                                            input.forEach(el => {
                                                value.push(el.value[0].toUpperCase());
                                            });
                                            output.value = value.join(', ');
                                        }

                                        if (typeof handler === 'function') {
                                            handler();
                                        }
                                    },
                                }),
                                ` ${option.value}`,
                            );
                        }),
                    ),
                ) : '',
            ),
        );
    },

    createDate(config, handler) {
        const date = config.date || new Date();
        const locale = config.locale || new Locale();

        const hour = createElement('select', { 'data-input-name': 'filterDateHourSelect' });
        for (let i = 0; i < 24; i++) {
            hour.children.push(
                createElement('option', { value: i }, (i < 10 ? '0' : '') + i),
            );
        }

        const minute = createElement('select', { 'data-input-name': 'filterDateMinuteSelect' });
        for (let i = 0; i < 60; i++) {
            minute.children.push(
                createElement('option', { value: i }, (i < 10 ? '0' : '') + i),
            );
        }

        const day = createElement('select', { 'data-input-name': 'filterDateDaySelect' });
        for (let i = 1; i < 32; i++) {
            day.children.push(
                createElement('option', { value: i }, (i < 10 ? '0' : '') + i),
            );
        }

        const month = createElement('select', { 'data-input-name': 'filterDateMonthSelect' });
        for (let i = 0; i < 12; i++) {
            month.children.push(
                createElement('option', { value: i }, locale.lmsg(`month${i + 1}`)),
            );
        }

        const year = createElement('select', { 'data-input-name': 'filterDateYearSelect' });
        for (let i = 2000; i <= date.getFullYear(); i++) {
            year.children.push(
                createElement('option', { value: i }, i),
            );
        }

        return createElement('.dropdown',
            createElement('.input-group',
                {
                    onclick: event => {
                        this.onDateSearchFilterOpen(event.target.closest('.dropdown'));
                    },
                },
                createElement('input.form-control[type="text"][readonly]', {
                    placeholder: config.placeholder || null,
                }),
                createElement('span.input-group-btn',
                    createElement('button.btn.dropdown-toggle[type="button"]',
                        {
                            'data-action-name': 'filterDateOpenForm',
                        },
                        createElement('span.caret'),
                    ),
                ),
            ),
            createElement('ul.dropdown-menu',
                {
                    onclick(event) {
                        event.preventDefault();
                        event.stopPropagation();
                    },
                },
                createElement('li',
                    createElement('.filter-date-form',
                        createElement('.form-row',
                            createElement('.inline-fields-group',
                                hour, ' : ', minute,
                            ),
                            createElement('.inline-fields-group',
                                day, ' ', month, ' ', year,
                            ),
                            createElement('.inline-fields-group',
                                createElement('button.btn[type="button"]', {
                                    'data-action-name': 'filterDateApply',
                                    onclick: event => {
                                        event.target.closest('.dropdown').classList.toggle('open');
                                        this.onDateSearchFilterOpen(event.target.closest('.dropdown'));
                                        this.onDateSearchFilterChange(event.target.closest('.dropdown'));
                                        if (typeof handler === 'function') {
                                            handler();
                                        }
                                    },
                                }, Locale.getSection('components.buttons').lmsg('apply')),
                                createElement('button.btn[type="button"]', {
                                    'data-action-name': 'filterDateClear',
                                    onclick: event => {
                                        event.target.closest('.dropdown').classList.toggle('open');
                                        event.target.closest('.dropdown').querySelector('input').value = '';
                                        this.onDateSearchFilterOpen(event.target.closest('.dropdown'));
                                        if (typeof handler === 'function') {
                                            handler();
                                        }
                                    },
                                }, locale.lmsg('clear')),
                            ),
                        ),
                        createElement('.form-row.filter-actions-row',
                            createElement('a.toggler', {
                                onclick: event => {
                                    this.onDateSearchFilterModify(event.target.closest('.dropdown'), new Date(date.getTime() - (3600 * 1000)));
                                    if (typeof handler === 'function') {
                                        handler();
                                    }
                                },
                            }, locale.lmsg('hourAgo')),
                            ' ',
                            createElement('a.toggler', {
                                onclick: event => {
                                    this.onDateSearchFilterModify(event.target.closest('.dropdown'), new Date(date.getTime() - (86400 * 1000)));
                                    if (typeof handler === 'function') {
                                        handler();
                                    }
                                },
                            }, locale.lmsg('prevDay')),
                        ),
                    ),
                ),
            ),
        );
    },

    enumOptionsView(options, value) {
        return options.map(function (option) {
            if ('value' === option.type) {
                return this.enumValueView(option, value);
            }
            return this.enumGroupValue(option, value);
        }.bind(this));
    },

    enumValueView(option, value) {
        return createElement('option', {
            value: option.value,
            selected: option.value === value,
        }, escapeHtml(option.label));
    },

    enumGroupValue(option, value) {
        return createElement('optgroup',
            {
                label: option.label,
            },
            this.enumOptionsView(option.options, value),
        );
    },

    // eslint-disable-next-line no-unused-vars
    onMultiEnumSearchFilterChange(element) {
        // for custom onChange handler
    },

    onDateSearchFilterOpen(element) {
        if (!element.classList.contains('open')) {
            return;
        }

        let date = new Date(element.querySelector('input').value);
        if (isNaN(date.getTime())) {
            date = new Date();
        }

        this._dateSearchFieldFromDate(element, date);
    },

    onDateSearchFilterChange(element) {
        const date = this._dateSearchFieldToDate(element);

        const yyyy = date.getFullYear();
        const mm = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
        const dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
        const h = (date.getHours() < 10 ? '0' : '') + date.getHours();
        const m = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

        element.querySelector('input').value = `${yyyy}-${mm}-${dd} ${h}:${m}`;
    },

    onDateSearchFilterModify(element, date) {
        this._dateSearchFieldFromDate(element, date);
    },

    _dateSearchFieldFromDate(element, date) {
        const elements = element.querySelectorAll('select');
        elements[0].value = date.getHours();
        elements[1].value = date.getMinutes();
        elements[2].value = date.getDate();
        elements[3].value = date.getMonth();
        elements[4].value = date.getFullYear();
    },

    _dateSearchFieldToDate(element) {
        const elements = element.querySelectorAll('select');
        return new Date(elements[4].value, elements[3].value, elements[2].value, elements[0].value, elements[1].value);
    },
};

export default Filters;
