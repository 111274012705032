// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { gql } from '@apollo/client';
import { Locale } from 'jsw';
import apolloClient from '../apolloClient';

const PASSWORD_ESTIMATION_QUERY = gql`
    query PasswordEstimation($password: String!) {
        passwordEstimation(password: $password) {
            level
            suggestions
        }
    }
`;

const defaultEstimateFn = async password => {
    const { data: { passwordEstimation } } = await apolloClient.query({
        query: PASSWORD_ESTIMATION_QUERY,
        variables: { password },
    });
    return passwordEstimation;
};

export default async (password, estimateFn = defaultEstimateFn) => {
    const [{ level, suggestions }, locale] = await Promise.all([
        estimateFn(password),
        Locale.loadSection('components.password.suggestions'),
    ]);

    return {
        password,
        strength: ['VeryWeak', 'Weak', 'Medium', 'Strong'][level] ?? 'VeryStrong',
        suggestions: suggestions.map(suggestion => locale.lmsg(suggestion)),
    };
};
