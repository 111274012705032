// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

/* eslint-disable react/no-multi-comp */

import { Container } from './container';
import { Component } from './component';
import { Tooltip } from './tooltip';
import render from './render';
import createElement from './createElement';
import escapeAttribute from './escapeAttribute';
import escapeHtml from './escapeHtml';
import { createHtmlElement } from 'helpers/createHtmlElement';

import './tool-list.less';

/**
 * Example usage:
 *
 *     @example
 *     new ToolList({
 *         renderTo: document.body,
 *         items: [{
 *             componentType: toollist.Panel,
 *             title: 'Components',
 *             image: 'class-icons/component-large.png',
 *             items: [{
 *                 componentType: toollist.Item,
 *                 title: Box,
 *                 href: './Box',
 *                 target: '_parent'
 *             }, {
 *                 componentType: toollist.Item,
 *                 title: Button,
 *                 href: './Button',
 *                 target: '_parent',
 *                 attention: true
 *             }]
 *         }, {
 *             componentType: toollist.Panel,
 *             title: 'Classes',
 *             image: 'class-icons/class-large.png',
 *             items: [{
 *                 componentType: toollist.Item,
 *                 title: Locale,
 *                 href: './Locale',
 *                 target: '_parent'
 *             }]
 *         }, {
 *             componentType: toollist.Panel,
 *             title: 'Singletons',
 *             image: 'class-icons/singleton-large.png',
 *             items: [{
 *                 componentType: toollist.Item,
 *                 title: Cookie,
 *                 href: './Cookie',
 *                 target: '_parent'
 *             }]
 *         }]
 *     });
 */
export class ToolList extends Container {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._tools = this._getConfigParam('tools', null);
        this._cls = this._getConfigParam('cls', 'tools-list-box');
        this._contentAreaId = `${this._id}-tools-list`;

        if (Array.isArray(this._tools) && !this._operations.length) {
            this._operations = null;
        }
    }

    _initComponentElement() {
        super._initComponentElement();
        this._updateComponentElement((
            `<div id="${this._id}-box-area" class="box-area">` +
                `<div id="${this._id}-content" class="content">` +
                        `<div id="${this._id}-content-area" class="content-area">` +
                            `<ul id="${this._id}-tools-list" class="tools-list"></ul>` +
                        '</div>' +
                '</div>' +
            '</div>'
        ));
    }

    render() {
        super.render();
        Tooltip.initData(this._componentElement);
    }
}

class Panel extends Container {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._contentAreaId = `${this._id}-tools-list`;
        this._image = this._getConfigParam('image', '');
        this._title = this._getConfigParam('title', '');
    }

    _initComponentElement() {
        const attributes = { class: 'tools-item' };
        const forcedId = this._getConfigParam('id', null);
        if (forcedId) {
            attributes.id = forcedId;
        }

        this._componentElement = createHtmlElement('li', attributes);
        render(
            this._componentElement,
            createElement('div', { class: 'tool-block' },
                createElement('span', { class: 'tool-icon' }, createElement('img', { src: this._image })),
                createElement('span', { class: 'tool-name' }, this._title),
                createElement('ul', { id: `${this._id}-tools-list`, class: 'tool-info' }),
            ),
        );
    }
}

class Item extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._handler = this._getConfigParam('handler', false);
        if (typeof this._handler === 'string') {
            // eslint-disable-next-line no-eval
            eval(`this._handler = ${this._handler}`);
        }
        this._href = this._getConfigParam('href', '#');
        this._target = this._getConfigParam('target', null);
        this._title = this._getConfigParam('title', '');
        this._disabled = this._getConfigParam('disabled', false);
        this._attention = this._getConfigParam('attention', false);
    }

    _initComponentElement() {
        const href = this._disabled ? '#' : this._href;

        let title = escapeHtml(this._title);
        if (this._attention) {
            title = createHtmlElement('span', {
                class: 'hint-attention',
            });
            title.innerHTML = title;
        }

        this._componentElement = createHtmlElement('li', {
            id: this._getConfigParam('id', null),
        })
            .update(createHtmlElement('a', {
                href,
                target: this._target,
            }).update(title));

        if (this._handler && !this._disabled) {
            const handler = this._handler;
            this._componentElement.querySelector('a').addEventListener('click', e => {
                e.preventDefault();
                handler.call(this, e);
            });
        }
    }

    _addTooltips() {
        const description = this._getConfigParam('description');
        if (description) {
            this._tooltip = Tooltip.init(this._componentElement.querySelector('a'), { text: description });
        }
    }
}

class Button extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._handler = this._getConfigParam('handler', false);
        if (typeof this._handler === 'string') {
            // eslint-disable-next-line no-eval
            eval(`this._handler = ${this._handler}`);
        }
        this._href = this._getConfigParam('href', '#');
        this._target = this._getConfigParam('target', null);
        this._image = this._getConfigParam('image', '');
        this._disabledImage = this._getConfigParam('disabledImage', this._image);
        this._title = this._getConfigParam('title', '');
        this._description = this._getConfigParam('description', '');
        this._disabled = this._getConfigParam('disabled', false);
    }

    _initComponentElement() {
        const attributes = { class: 'tools-item' };
        const forcedId = this._getConfigParam('id', null);
        if (forcedId) {
            attributes.id = forcedId;
        }

        this._componentElement = createHtmlElement('li', attributes);

        let href;
        let image;
        let cls = 'tool-block';

        if (this._disabled) {
            cls += ' tool-block-disabled';
            href = '#';
            image = this.getImagePath(this._disabledImage);
        } else {
            href = this._href;
            image = this.getImagePath(this._image);
        }
        render(this._componentElement,
            createElement(
                'a',
                {
                    class: cls,
                    target: this._target,
                    href,
                },
                (
                    '<span class="tool-icon">' +
                        `<img src="${escapeAttribute(image)}"/>` +
                    '</span>' +
                    `<span class="tool-name">${escapeHtml(this._title)}</span>` +
                    `<span class="tool-info">${escapeHtml(this._description)}</span>`
                ),
            ),
        );

        if (this._handler && !this._disabled) {
            this._componentElement.addEventListener('click', this._handler);
        }
    }

    _addTooltips() {
        // don't show tooltips, 'cause we've inline descriptions
    }

    getImagePath(path) {
        return `${Jsw.skinUrl}/${path}`;
    }
}

export const toollist = { Panel, Item, Button };
