// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { List } from './list';
import createElement from './createElement';

export class GroupsList extends List {
    _getGroupRowClass() {
        return '';
    }

    _initConfiguration(config) {
        this._groupBy = false;
        this._groupsConfig = [];

        super._initConfiguration(config);
        this._groupBy = this._getConfigParam('groupBy', false);
        this._groupsConfig = this._getConfigParam('groupsConfig', false);
        this._getGroupRowClass = this._getConfigParam('getGroupRowClass', this._getGroupRowClass);
    }

    listDataView(listData = this._data, indexOffset = 0) {
        if (!listData) {
            return null;
        }

        if (this._groupBy) {
            listData.sort((a, b) => {
                if (a[this._groupBy] > b[this._groupBy]) {
                    return 1;
                }
                if (a[this._groupBy] < b[this._groupBy]) {
                    return -1;
                }
                return 0;
            });

            const groupedData = {};
            listData.forEach(item => {
                if (!groupedData[item[this._groupBy]]) {
                    groupedData[item[this._groupBy]] = {
                        id: item[this._groupBy],
                        items: [],
                    };
                }
                groupedData[item[this._groupBy]].items.push(item);
            });

            return createElement('tbody',
                Object.keys(groupedData).map((groupId, groupIndex) => {
                    const group = groupedData[groupId];
                    return this.groupDataRowView(group, groupIndex, indexOffset);
                }),
            );
        }

        return createElement('tbody',
            listData.map((item, index) => this.rowView(item, index, indexOffset)),
        );
    }

    groupDataRowView(group, groupIndex, indexOffset) {
        return [
            createElement(`tr.${this._getGroupRowClass(group)}.${(groupIndex + indexOffset) % 2 ? 'even' : 'odd'}`, {
                colspan: this._columns.length,
                'data-row-id': group.id,
            }, `<b>${this._getGroupName(group)}</b>`),
            ...group.items.map((item, index) => this.rowView(item, index, indexOffset)),
        ];
    }

    _getGroupName(group) {
        return this._groupsConfig[group.id] || group.id;
    }
}
