// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

/* eslint-disable camelcase */

import { useState, useMemo, useEffect, useInsertionEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FormFieldText, FormFieldPassword, Action, StatusMessage, Link } from '@plesk/ui-library';
import { Locale, render } from 'jsw';
import JswComponent from '../../jsw/JswComponent';
import RouteLink from 'components/common/Link';
import Form from 'components/Form';
import { withLoginLayout } from '../../layouts/LoginLayout';
import LoginMobileApplicationPromotion from './LoginMobileApplicationPromotion';
import SuggestResolvableHostname from './SuggestResolvableHostname';
import LocaleSwitcher from './LocaleSwitcher';
import { usePageData } from 'helpers/pageData';

const Translate = Locale.getTranslate('components.forms.login');

import LOGIN_PAGE_QUERY from 'queries/LoginPage.graphql';

const LoginPage = ({ values: defaultValues = undefined }) => {
    const navigate = useNavigate();
    const { status } = usePageData();
    const { data: {
        config,
        serverInfo: { isFirstLogin, cloudType },
        loginContentInclude: { headContent, bodyContent },
    } } = useQuery(LOGIN_PAGE_QUERY);
    const values = useMemo(() => ({
        login_name: '',
        passwd: '',
        locale_id: 'default',
        ...defaultValues,
    }), [defaultValues]);
    const [errors, setErrors] = useState({});

    useInsertionEffect(() => {
        if (!headContent) {
            return;
        }

        const addedElements = [];
        const first = document.head.childNodes.length;
        render(document.head, headContent);
        const last = document.head.childNodes.length;

        for (let i = first; i < last; i++) {
            addedElements.push(document.head.childNodes[i]);
        }

        return () => {
            addedElements.forEach(el => {
                document.head.removeChild(el);
            });
        };
    }, [headContent]);

    useEffect(() => {
        document.querySelector('input[name="locale_id"]').value = values?.locale_id ?? 'default';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = values => {
        const errors = {};
        if (values.login_name === '') {
            errors.login_name = {
                empty: <Translate content="enterUsername" />,
            };
        }
        if (values.passwd === '') {
            errors.passwd = {
                empty: <Translate content="enterPassword" />,
            };
        }
        setErrors(errors);

        if (!Object.keys(errors).length) {
            return {
                ...values,
                locale_id: document.querySelector('input[name="locale_id"]').value,
            };
        }
    };

    return (
        <>
            {(isFirstLogin && cloudType === 'DO') ? (
                <StatusMessage intent="info" className="login-page__info">
                    <Translate
                        content="digitalOceanMsg"
                        params={
                            { link: (
                                <Link
                                    // eslint-disable-next-line max-len
                                    href="https://docs.plesk.com/en-US/obsidian/deployment-guide/plesk-installation-and-upgrade-on-public-cloud-services/installing-plesk-on-digitalocean.79699/#accessing-plesk-for-the-first-time"
                                    target="_blank"
                                >
                                    <Translate content="readGuide" />
                                </Link>
                            ) }
                        }
                    />
                </StatusMessage>
            ) : (isFirstLogin && cloudType === 'AMI_LIGHTSAIL') ? (
                <StatusMessage intent="info" className="login-page__info">
                    <Translate
                        content="amazonLightsailMsg"
                        params={
                            { link: (
                                <Link
                                    // eslint-disable-next-line max-len
                                    href="https://docs.plesk.com/en-US/obsidian/deployment-guide/plesk-installation-and-upgrade-on-public-cloud-services/installing-plesk-on-amazon-lightsail.79028/#accessing-plesk-for-the-first-time"
                                    target="_blank"
                                >
                                    <Translate content="readGuide" />
                                </Link>
                            ) }
                        }
                    />
                </StatusMessage>
            ) : null }
            <Form
                id="form-login"
                method="post"
                applyButton={false}
                cancelButton={false}
                statusMessages={status}
                submitButton={{
                    name: 'send',
                    children: <Translate content="loginButtonLabel" />,
                    fill: true,
                    className: 'login-page__login-button',
                    'data-action': 'log-in',
                }}
                onSubmit={handleSubmit}
                vertical
                values={values}
                errors={errors}
                className="login-page__form"
            >
                <div id="loginSection">
                    <FormFieldText
                        name="login_name"
                        label={<Translate content="loginLabel" />}
                        autoFocus
                        size="fill"
                    />
                    <FormFieldPassword
                        name="passwd"
                        label={<Translate content="passwdLabel" />}
                        hideGenerateButton
                        hidePasswordMeter
                        size="fill"
                    />
                    <input type="hidden" name="locale_id" />
                </div>
            </Form>
            <div className="login-page__links">
                {config?.login?.howToLoginArticle ? (
                    <Action
                        icon="question-mark-circle"
                        href={config.login.howToLoginArticle}
                        target="_blank"
                        data-action="open-how-to"
                    >
                        <Translate content="whatUsernamePassword" />
                    </Action>
                ) : null}
                <Action
                    icon="lock-closed"
                    href="/get_password.php"
                    onClick={e => {
                        e.preventDefault();
                        const locale = document.querySelector('input[name="locale_id"]').value;
                        const url = e.target.closest('a').getAttribute('href');
                        navigate(`${url}${locale && locale !== 'default' ? `?locale=${encodeURIComponent(locale)}` : ''}`);
                    }}
                    data-action="reset-password"
                >
                    <Translate content="forgotPasswordLabel" />
                </Action>
                {config?.gdpr?.cookieSettings?.loginFormEntrypointEnabled && (
                    <Action
                        component={RouteLink}
                        icon="globe"
                        to="?modals[cookie-policy-preferences]=true"
                        data-action="open-cookies-policy"
                    >
                        <Translate content="cookies" />
                    </Action>
                )}
            </div>
            {bodyContent ? (
                <JswComponent>{bodyContent}</JswComponent>
            ) : null}
        </>
    );
};

LoginPage.propTypes = {
    values: PropTypes.shape({
        login_name: PropTypes.string,
        passwd: PropTypes.string,
        locale_id: PropTypes.string,
    }),
};

export default withLoginLayout(LoginPage, ({ values }) => {
    const { data: {
        locales,
        viewer: { resolvableHostname, mobileApplicationPromotion, background },
        loginContentInclude: { bodyContent },
    } } = useQuery(LOGIN_PAGE_QUERY);

    return {
        actions: locales.length > 1 ? (
            <LocaleSwitcher
                locales={locales}
                value={values?.locale_id ?? 'default'}
                onChange={value => {
                    document.querySelector('input[name="locale_id"]').value = value;
                    document.documentElement.lang = value.split('-')[0];

                    const locale = locales.find(({ code }) => code === value);
                    if (bodyContent || locale?.isRtl !== (document.documentElement.dir === 'rtl')) {
                        document.getElementById('form-login').submit();
                    } else {
                        Locale.loadSections(Object.keys(Locale.sections), value);
                    }
                }}
            />
        ) : undefined,
        contentAddon: [
            resolvableHostname ? (
                <SuggestResolvableHostname link={resolvableHostname} />
            ) : null,
            mobileApplicationPromotion ? (
                <LoginMobileApplicationPromotion {...mobileApplicationPromotion} />
            ) : null,
        ],
        background,
    };
});
