// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const HOURS_IN_MS = 1000 * 60 * 60;

const SEC_IN_MINUTE = 60;
const SEC_IN_HOUR = 60 * SEC_IN_MINUTE;
const SEC_IN_DAY = 24 * SEC_IN_HOUR;

export const getSysdate = () => new Date();

export const addDays = (daysCount, date = new Date()) => {
    const copy = new Date(date.getTime());
    copy.setDate(copy.getDate() + daysCount);

    return copy;
};

export const addHours = (hoursCount, date = new Date()) => {
    const copy = new Date(date.getTime());
    copy.setHours(copy.getHours() + hoursCount);

    return copy;
};

export const isSameDay = (date1, date2) => {
    const yearsAreEqual = date1.getYear() === date2.getYear();
    const monthsAreEqual = date1.getMonth() === date2.getMonth();
    const daysAreEqual = date1.getDate() === date2.getDate();

    return yearsAreEqual && monthsAreEqual && daysAreEqual;
};

export const getTimezoneShift = timezone => {
    const [hours, minutes] = timezone.split(':');

    return +hours < 0 ?
        -(Math.abs(hours) + (minutes / 60)) :
        +hours + (minutes / 60);
};

const MS_IN_SECOND = 1000;


export const formatDate = date => new Date(date)
    .toLocaleDateString(document.documentElement.lang ?? 'en', { month: 'long', day: 'numeric', year: 'numeric' });

export const formatDateFromTimestamp = seconds => formatDate(seconds * MS_IN_SECOND);

export const toDatePeriod = seconds => {
    const days = Math.floor(seconds / SEC_IN_DAY);
    const daysInSeconds = days * SEC_IN_DAY;
    const hours = Math.floor((seconds - daysInSeconds) / SEC_IN_HOUR);
    const hoursInSeconds = hours * SEC_IN_HOUR;
    const minutes = Math.floor((seconds - daysInSeconds - hoursInSeconds) / SEC_IN_MINUTE);
    const minutesInSeconds = minutes * SEC_IN_MINUTE;

    return {
        days,
        hours,
        minutes,
        seconds: seconds - daysInSeconds - hoursInSeconds - minutesInSeconds,
    };
};


export const getEndOfDay = timestamp => {
    const date = new Date(timestamp);
    date.setHours(23, 59, 59, 999);
    return date;
};

export const getStartOfDay = timestamp => {
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);
    return date;
};

export const getStartOfMonth = timestamp => {
    const date = new Date(timestamp);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
};

export const getEndOfMonth = timestamp => {
    const date = new Date(timestamp);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    date.setHours(23, 59, 59, 999);
    return date;
};

export const getStartOfWeek = timestamp => {
    const date = new Date(timestamp);
    const dayOfWeek = date.getDay();

    const diff = dayOfWeek * -1;
    date.setDate(date.getDate() + diff);
    date.setHours(0, 0, 0, 0);

    return date;
};


export const getEndOfWeek = timestamp => {
    const date = new Date(timestamp);
    const dayOfWeek = date.getDay();
    const diff = 6 - dayOfWeek;
    date.setDate(date.getDate() + diff);
    date.setHours(23, 59, 59, 999);

    return date;
};

export const getDaysInMonthFromTimestamp = timestamp => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth();

    return new Date(year, month + 1, 0).getDate();
};

export const getDaysInPreviousMonth = timestamp => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth();

    const previousMonth = month === 0 ? 11 : month - 1;
    const previousMonthYear = month === 0 ? year - 1 : year;

    // Create a new date for the last day of the previous month
    return new Date(previousMonthYear, previousMonth + 1, 0).getDate();
};

export const toUnixTime = timestamp => Math.floor(timestamp / MS_IN_SECOND);
export const toPhpDate = timestamp => new Date(timestamp).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
